import { typeDevice } from 'common/constants'

export const detectWebCamera = (): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    if (process.env.browser) {
      const getUserMedia =
        navigator.mediaDevices &&
        navigator.mediaDevices.enumerateDevices &&
        typeof navigator.mediaDevices.enumerateDevices === 'function'

      if (!getUserMedia) {
        console.warn('Method enumerateDevices() not support')
        return reject(false)
      }

      return navigator.mediaDevices.enumerateDevices().then(
        (devices) => {
          const findVideoDevice = devices.find(
            (device) => device.kind === typeDevice.videoDeviceInput
          )
          if (findVideoDevice) {
            return resolve(true)
          } else {
            console.warn('WebCamera not detect')
            return reject(false)
          }
        },
        (error) => {
          console.warn('Media devices error', error)
          return reject(false)
        }
      )
    }

    return reject(false)
  })
}
