import { Coordinates } from 'reducers/geolocation/geolocationReducer.types'
import { fetchQueryApi } from 'api/fetchApi'
import { ProfileStatuses } from 'api/api.types'
import { paths } from 'api/generated/ratings_v2.voting'
import { GQLGender } from 'generated-graphql/graphql'

type Method =
  | paths['/ratings/v2/voting/photos']['get']
  | paths['/ratings/v2/voting/fancy-photos']['get']
export type Result = Method['responses']['200']['schema']
type Query = Method['parameters']['query']

export const statuses = [
  ProfileStatuses.online,
  ProfileStatuses.hasVerifiedPhoto,
  ProfileStatuses.real,
  ProfileStatuses.spaceTimeLocation,
].join(',')

const commonFetchVotingPhotoApi = (
  url: '/ratings/v2/voting/photos' | '/ratings/v2/voting/fancy-photos',
  ageFrom: number,
  ageTo: number,
  heightFrom: number,
  heightTo: number,
  location: string,
  gender: string,
  limit = 20,
  coordinates: Coordinates | null,
  offsetPhotoId?: number
) => {
  if (coordinates) {
    const latitude = coordinates.latitude
    const longitude = coordinates.longitude

    return fetchQueryApi<Result, Query>(url, {
      'coordinates[latitude]': latitude,
      'coordinates[longitude]': longitude,
      ageFrom,
      ageTo,
      heightFrom,
      heightTo,
      gender: gender as GQLGender,
      limit: limit,
      statuses: statuses,
      offsetPhotoId: offsetPhotoId,
    })
  }

  /**
   * Ошибка типов, по идее координаты в случае location
   * не нужны.
   */
  // @ts-ignore
  return fetchQueryApi<Result, Query>(url, {
    ageFrom,
    ageTo,
    heightFrom,
    heightTo,
    gender: gender as GQLGender,
    limit: limit,
    statuses: statuses,
    offsetPhotoId: offsetPhotoId,
    location,
  })
}

export const fetchVotingPhotoApi = (
  authorized: boolean,
  ageFrom: number,
  ageTo: number,
  heightFrom: number,
  heightTo: number,
  location: string,
  gender: string,
  coordinates: Coordinates | null,
  photoRatingLimit: number = 20,
  offsetPhotoId?: number
) => {
  return commonFetchVotingPhotoApi(
    authorized
      ? '/ratings/v2/voting/photos'
      : '/ratings/v2/voting/fancy-photos',
    ageFrom,
    ageTo,
    heightFrom,
    heightTo,
    location,
    gender,
    photoRatingLimit,
    coordinates,
    offsetPhotoId
  )
}
