export enum PromoCase {
  Likes = 'likes',
  Incognito = 'incognito',
  Search = 'search',
  Limits = 'limits',
  Stickers = 'stickers',
  Favorites = 'favorites',
  Topup = 'top-up',
  Featured = 'featured',
  Photoline = 'photoline',
  Upload = 'upload-photo',
  Adv = 'adv',
}

export const PromoCases = {
  VIP: {
    LIKES: PromoCase.Likes,
    INCOGNITO: PromoCase.Incognito,
    SEARCH: PromoCase.Search,
    LIMITS: PromoCase.Limits,
    STICKERS: PromoCase.Stickers,
    FAVORITES: PromoCase.Favorites,
  },
  COMMON: {
    TOPUP: PromoCase.Topup,
    FEATURED: PromoCase.Featured,
    PHOTOLINE: PromoCase.Photoline,
  },
  UPLOAD: PromoCase.Upload,
  ADV: PromoCase.Adv,
} as const

export enum SearchPromoTypes {
  Service = 'service',
}
export interface SearchItemPromo {
  type: SearchPromoTypes.Service
  case: PromoCase
}
