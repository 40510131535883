import { PROMO_ITEMS_BY_CHUNK } from 'components/page/Search/searchPromoFunctions'
import { createRandomArrayIndex } from 'functions/array'
import { getPromoService } from 'components/page/Search/function/getPromoService'
import {
  PromoCases,
  PromoCase,
  SearchItemPromo,
} from 'common-constants/promoCases'
import { createPromoPotentialList } from './createPromoPotentialList'

export const createPromo = (
  vip: boolean,
  authorized: boolean,
  lastPromoInsert: SearchItemPromo | undefined
) => {
  if (!authorized) {
    return []
  }

  const promoIterator = createPromoIterator(vip)

  return promoIterator.getNextChunk(lastPromoInsert)
}

export const createPromoIterator = (hasActiveVip: boolean) => {
  const promoListType = createPromoPotentialList(hasActiveVip)

  let shouldPushAdAtCurrentIteration = Boolean(Math.floor(Math.random() * 2))

  const getNextChunk = (
    lastPromoInsert: SearchItemPromo | undefined
  ): SearchItemPromo[] => {
    let lastPromoInsertCurrent = lastPromoInsert
    const promoListArray = [...promoListType]
    const chunk: SearchItemPromo[] = []

    while (chunk.length < PROMO_ITEMS_BY_CHUNK) {
      const currentPromoInsert = generateRandomPromoElement(
        filterPromosList(promoListArray, lastPromoInsertCurrent)
      )
      chunk.push(currentPromoInsert)
      lastPromoInsertCurrent = currentPromoInsert

      shouldPushAdAtCurrentIteration = !shouldPushAdAtCurrentIteration
    }

    return chunk
  }

  return {
    getNextChunk,
  }
}

export const filterPromosList = (
  array: PromoCase[],
  lastPromoInsert: SearchItemPromo | undefined
) => {
  if (!lastPromoInsert) {
    return array
  }

  if (PromoCases.VIP[lastPromoInsert.case.toUpperCase()]) {
    const vipCases = (Object.values(PromoCases.VIP) as unknown) as PromoCase

    return array.filter(
      (item: PromoCase) =>
        item !== lastPromoInsert.case && !vipCases.includes(item)
    )
  }

  return array.filter((item: PromoCase) => item !== lastPromoInsert.case)
}

export const generateRandomPromoElement = (array: PromoCase[]) =>
  getPromoService(array[createRandomArrayIndex(array)])
