import { AsyncThunkAction } from 'actions/types'
import { simpleSearchApi } from 'api/mainSearchApi'
import { Cursor, SearchListApi } from 'api/search/search.types'
import { ProfileRouteParams } from 'components/paths'
import { createPromo } from 'components/page/Search/function/createPromo'
import { AsyncAction } from 'actions/actions.types'

export const FETCH_SEARCH_LIST_PROFILE = 'FETCH_SEARCH_LIST_PROFILE' as const
export const CLEAR_SEARCH_LIST_PROFILE = 'CLEAR_SEARCH_LIST_PROFILE' as const
export const CHANGE_INITIAL_LOADED = 'CHANGE_INITIAL_LOADED' as const

interface FetchSearchListProfileAction extends AsyncAction<SearchListApi> {
  type: typeof FETCH_SEARCH_LIST_PROFILE
  promos?: []
  userId?: number
  cursor: Cursor
}

interface ClearSearchListProfileAction {
  type: typeof CLEAR_SEARCH_LIST_PROFILE
}

interface ChangeInitialLoadedAction {
  type: typeof CHANGE_INITIAL_LOADED
}

export const fetchSearchListProfileAction = (
  limit: number,
  params: Cursor & ProfileRouteParams,
  headers = {}
): AsyncThunkAction => async (dispatch, getState) => {
  const {
    searchListProfile: { cursor: cursorStore, lastPromoInsert },
    authorizationReducer: { authorized },
    userReducer: { vip },
  } = getState()

  const {
    searchListProfile: { lastPromoInsert: updatedLastPromoInsert },
  } = getState()

  const promos = createPromo(vip, authorized, updatedLastPromoInsert)

  const { userId, ...cursorSearchParams } = params

  /**
   * Изначально в store searchId = 0, после первого запроса, cursor меняется.
   */
  const cursor =
    cursorStore && cursorStore.searchId !== 0 ? cursorStore : cursorSearchParams

  await dispatch({
    type: FETCH_SEARCH_LIST_PROFILE,
    promise: () => simpleSearchApi(limit, cursor, headers),
    promos,
    userId,
    cursor,
  })
}

export const clearSearchListProfileAction = () => ({
  type: CLEAR_SEARCH_LIST_PROFILE,
})

export const changeInitialLoadedAction = () => ({
  type: CHANGE_INITIAL_LOADED,
})

export type SearchListProfileTypes =
  | FetchSearchListProfileAction
  | ClearSearchListProfileAction
  | ChangeInitialLoadedAction
