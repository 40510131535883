import { fetchApi, fetchQueryApi, postApi, putApi } from 'api/fetchApi'
import { paths } from 'api/generated/ratings_v2.preferences'

type MethodAuthorizedGet = paths['/ratings/v2/preferences']['get']
type MethodNotAuthorizedGet = paths['/ratings/v2/voting/fancy-photos/preferences']['get']
type MethodSavePut = paths['/ratings/v2/preferences']['put']
type MethodNormalize = paths['/ratings/v2/preferences/normalize']

type ParametersNotAuthorizedGet = MethodNotAuthorizedGet['parameters']
type ParametersSavePut = MethodSavePut['parameters']['body']['body']

export type ResultAuthorizedGet = MethodAuthorizedGet['responses']['200']['schema']
export type ResultNotAuthorizedGet = MethodNotAuthorizedGet['responses']['200']['schema']
export type ResultSavePut = MethodSavePut['responses']['204']

type ResultNormalizePost = MethodNormalize['post']['responses']['204']

export const fetchPhotoRatingSettingsListApi = () =>
  fetchApi<ResultAuthorizedGet>('/ratings/v2/preferences')

export const fetchPhotoRatingSettingsListNotAuthorizedApi = (
  locale: string
) => {
  return fetchQueryApi<ResultNotAuthorizedGet, ParametersNotAuthorizedGet>(
    '/ratings/v2/voting/fancy-photos/preferences',
    {
      locale,
    }
  )
}

export const savePhotoRatingSettingsListApi = ({
  ageFrom,
  ageTo,
  gender,
  coordinates,
  location,
  heightFrom,
  heightTo,
}) =>
  putApi<ResultSavePut, ParametersSavePut>('/ratings/v2/preferences', {
    ageFrom,
    ageTo,
    gender,
    coordinates,
    heightFrom,
    heightTo,
    locationStr: location,
  })

export const normalizePhotoRatingSettingsApi = () =>
  postApi<ResultNormalizePost, {}>('/ratings/v2/preferences/normalize')
