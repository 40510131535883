import { PromoCase, PromoCases } from 'common-constants/promoCases'

/** Используется только для поиска */
export const createPromoPotentialList = (hasVip: boolean): PromoCase[] => {
  return [
    // На первом месте всегда должно быть промо поднять профиль в поиске
    PromoCases.COMMON.TOPUP,
    PromoCases.COMMON.FEATURED,
    ...(hasVip ? [] : [PromoCases.VIP.SEARCH]),
  ].filter(Boolean)
}
