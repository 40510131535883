import {
  PromoCase,
  SearchItemPromo,
  SearchPromoTypes,
} from 'common-constants/promoCases'

export const buildPromoStruct = (
  type: SearchPromoTypes,
  promoCase: PromoCase
): SearchItemPromo => ({
  type,
  case: promoCase,
})
