import { fetchApi } from 'api/index'

export function addInterestInSearchOptionsApi(interestId: number) {
  return fetchApi('/search/options/addInterest', {
    method: 'POST',
    body: JSON.stringify({
      interestId: interestId,
    }),
  })
}
