export const removeUrlQuery = (url: string) => url.split('?')[0]

/**
 * Почему-то было deprecated, не понял почему.
 * @param {*} url
 * @returns
 * Метод для открытия ссылки в новой вкладке. Не работает в мобильном сафари. Надо использовать обычную ссылку с target = '_blank'
 */
export const openUrlInNewTab = (url: string) =>
  Object.assign(document.createElement('a'), {
    target: '_blank',
    href: url,
  }).click()
