import { genericLayoutContextId } from 'components/layout/MambaLayout/Context'
import { mergeAllUrls } from 'functions/mergeAllUrls'

export const createSupportFormPath = (
  appUniWebPath,
  touch,
  contextId,
  ...rest
) => {
  if (contextId === genericLayoutContextId) {
    return mergeAllUrls(appUniWebPath, ...rest)
  } else {
    return mergeAllUrls(...rest)
  }
}
