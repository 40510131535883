import { ApiResult, fetchApi, postApi } from 'api/fetchApi'
import { paths } from 'api/generated/user_settings'

type Path = paths['/settings/isAllowedToChangeBirthDate']
type ConfirmPath = paths['/settings/changeBirthDate']

export const fetchAllowedChangeBirthDateApi = (): Promise<
  ApiResult<Path['get']['responses']['200']['schema']>
> => fetchApi('/settings/isAllowedToChangeBirthDate')

export const confirmUpdateUserBirthDateApi = (
  birthDate: string
): Promise<ApiResult<ConfirmPath['post']['responses']['204']>> =>
  postApi<
    ConfirmPath['post']['responses']['204'],
    ConfirmPath['post']['parameters']['body']['body']
  >('/settings/changeBirthDate', {
    birthDate,
  })
