import { addZeroBeforeTimeUnit } from 'functions/number/addZeroBeforeTimeUnit'

/**
 *
 * @param birtday [string]
 * @returns {
 *  day: [0-31],
 *  month: [0-11],
 *  year: number
 * }
 */

interface BirthDay {
  day: number
  month: number
  year: number
}

export const separateBirthDate = (birthDate: string): BirthDay => {
  const date = new Date(birthDate)

  return {
    day: date.getDate(),
    month: date.getMonth() + 1,
    year: date.getFullYear(),
  }
}

export const concatBirthDate = (day: number, month: number, year: number) =>
  `${year}-${addZeroBeforeTimeUnit(month)}-${addZeroBeforeTimeUnit(day)}`
